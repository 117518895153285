import React from 'react';
import './About.css';
import about from './images/about-pic (1).jpg';
import { FaBuilding, FaHammer, FaImage, FaPaintBrush } from 'react-icons/fa';
import team from './images/pic-c-1.png';

const About = () => {
    return (
        <div className="about-us-container">
            <div className="about-us-content">
                <div className="about-us-image">
                    <img src={about} alt="About Us" className='about-us-img' />
                </div>
                <div className="about-us-text">
                    <p className='about-head'>About Company</p>
                    <div className="about-section1">
                        <div className="about-square"></div>
                        <div className="about-line"></div>
                    </div>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown galley.</p>
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. remaining essentially unchanged. It was popularised in the .</p>
                </div>
            </div>
            <div className="services">
                <div className='content-section'>
                    <div className="service-card">
                        <div className="icon"><FaBuilding /></div>
                        <p className='service-head'>BUILDING</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="service-card">
                        <div className="icon"><FaPaintBrush /></div>
                        <p className='service-head'>RENNOVATION</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="service-card">
                        <div className="icon"><FaHammer /></div>
                        <p className='service-head'>DIGGING</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                    <div className="service-card">
                        <div className="icon"><FaImage /></div>
                        <p className='service-head'>INTERIOR</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                    </div>
                </div>
                <div className='image-section'>
                    <img src={team} alt="Team" />
                </div>
            </div>
        </div>
    );
};

export default About;
