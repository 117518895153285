import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import './Home.css';
import background1 from './images/bg1.webp';
import background2 from './images/bg2.webp';
import background3 from './images/bg3.jpg';
import background4 from './images/bg4.jpg';
import {  FaLifeRing, FaTrophy, FaThumbsUp, FaUsers, FaChartArea, FaCogs, FaHome } from 'react-icons/fa';
import { Ri24HoursLine } from "react-icons/ri";
import { TbHomeCheck } from "react-icons/tb";

const Home = () => {
    const [activeService, setActiveService] = useState(null);

    const handleServiceClick = (service) => {
        setActiveService(service);
    };

    return (
        <div>
            <section className="hero">
                <Carousel 
                    showThumbs={false} 
                    autoPlay 
                    infiniteLoop 
                    showStatus={false}
                    showArrows={false}
                >
                    <div>
                        <img className='carousel-img' src={background1} alt="Background 1" />
                    </div>
                    <div>
                        <img src={background2} alt="Background 2" />
                    </div>
                    <div>
                        <img src={background3} alt="Background 3" />
                    </div>
                    <div>
                        <img src={background4} alt="Background 4" />
                    </div>
                </Carousel>
                <div className="overlay"></div>
                <div className="hero-content">
                    <p className='carousel-head'>We Build <span>Your</span> Dreams</p>
                    <p>Dorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod teididunt ut labore et orem ipsum dolor sit ameo eiusmod tm do eiusmod teididunt ut labore et dolore.</p>
                </div>
            </section>
            <section className='home-card'>
               <div className='quality'>
               <span ><TbHomeCheck /></span>
                <div>
                    <p className='quality-head'>BEST QUALITY</p>
                    <p>Lorem ipsum dolor sit amet, cdipiscing elit, sed diam non dolore .</p>
                </div>
               </div>
               <div className='support'>
               <span><Ri24HoursLine /></span>
                <div>
                    <p className='support-head'>24 HOUR SUPPORT</p>
                    <p>Lorem ipsum dolor sit amet, cdipiscing elit, sed diam non dolore .</p>
                </div>
               </div>
               <a href='/contact'><button className='home-button' >CONTACT NOW</button></a>
            </section>
             


            <section className="why-choose-us">
                <p className='why-choose-us-head'>Why Choose Us</p>
                <div className="divider">
                    <div className="line"></div>
                    <div className="square"></div>
                    <div className="line"></div>
                </div>
                <p className=''>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
                <div className="why-choose-card">
                    <div
                        className={`service-item ${activeService === 'quality' ? 'active' : ''}`}
                        
                    >
                        <div className="icon"><FaLifeRing /></div>
                        <p className='service-item-head'>BEST QUALITY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                    <div
                        className={`service-item ${activeService === 'integrity' ? 'active' : ''}`}
                        onClick={() => handleServiceClick('integrity')}
                    >
                        <div className="icon"><FaTrophy /></div>
                        <p className='service-item-head'>INTEGRITY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                    <div
                        className={`service-item ${activeService === 'strategy' ? 'active' : ''}`}
                        onClick={() => handleServiceClick('strategy')}
                    >
                        <div className="icon"><FaThumbsUp /></div>
                        <p className='service-item-head'>STRATEGY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                    <div
                        className={`service-item ${activeService === 'strategy' ? 'active' : ''}`}
                        onClick={() => handleServiceClick('strategy')}
                    >
                        <div className="icon"><FaUsers/></div>
                        <p className='service-item-head'>SAFETY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                    <div
                        className={`service-item ${activeService === 'strategy' ? 'active' : ''}`}
                        onClick={() => handleServiceClick('strategy')}
                    >
                        <div className="icon"><FaChartArea /></div>
                        <p className='service-item-head'>COMMUNITY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                    <div
                        className={`service-item ${activeService === 'strategy' ? 'active' : ''}`}
                        onClick={() => handleServiceClick('strategy')}
                    >
                        <div className="icon"><FaCogs /></div>
                        <p className='service-item-head'>SUSTAINABILITY</p>
                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting indtrysum has been the Ipsum dummy of the printing indus.</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Home;
