import React from 'react';
import './Footer.css'; // Ensure you have this CSS file for styling
import logo from './images/Sup Con Logo-01.png'; // Make sure to have a logo image in your project directory

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column about-company">
          <img src={logo} alt="Company Logo" className="company-logo" />
          <p>Supreme is a leading construction company dedicated to providing top-quality services. We specialize in residential and commercial construction, renovation, and project management.</p>
        </div>
        <div className="footer-column contact-us">
          <p className='contact-us-head'>Contact Us</p>
          <div className="footer-divider">
                    <div className="footer-square"></div>
                    <div className="footer-line"></div>
                </div>
          <p>Address: 1234 Main St, Anytown, USA</p>
          <p>Phone: +919975085815/+918104080055</p>
          <p>Email: <a href="mailto:info@companyname.com">info@companyname.com</a></p>
        </div>
        <div className="footer-column quick-links">
          <p className='quick-links-head'>Quick Links</p>
          <div className="footer-divider">
                    <div className="footer-square"></div>
                    <div className="footer-line"></div>
                </div>
          <ul>
            <li><a href="/">Home</a></li>
            <li><a href="/about-us">About Us</a></li>
            
            <li><a href="/projects">Projects</a></li>
            <li><a href="/contact">Contact</a></li>
          </ul>
        </div>
       
      </div>
      <div className="footer-bottom">
        <p href='https://wa.link/cm2ul3'>&copy; 2024 Supreme Construction | All rights reserved By Shree Digital Marketing Agency</p>
      </div>
    </footer>
  );
}

export default Footer;
