import React from 'react';
import './Contact.css';
import { FaMobileScreen } from "react-icons/fa6";
import { TfiEmail } from "react-icons/tfi";
import { CiLocationOn } from "react-icons/ci";
export const Contact = () => {
  return (
    <div>
      <div className="contact-container">
        <div className="location-section">
          <h2 className="section-title">LOCATION</h2>
          <div className="title-decor">
            <div className="square"></div>
            <div className="line"></div>
          </div>
          <div className="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434509191!2d144.95373631550435!3d-37.8162794420215!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0xf0727b0f7bb80b2b!2s62%20Dean%20St%2C%20London%20W1D%204QF%2C%20UK!5e0!3m2!1sen!2s!4v1628520482018!5m2!1sen!2s"
              width="100%"
              height="450"
              allowFullScreen=""
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </div>
        </div>
        <div className="contact-form-section">
          <h2 className="section-title">CONTACT FORM</h2>
          <div className="title-decor">
            <div className="square"></div>
            <div className="line"></div>
          </div>
          <form className="contact-form">
            <div className="form-group">
              <input type="text" className="form-control" placeholder="Name" required />
            </div>
            <div className="form-group">
              <input type="email" className="form-control" placeholder="Email" required />
            </div>
            <div className="form-group">
              <textarea className="form-control" placeholder="Message" rows="5" required></textarea>
            </div>
            <div className="form-buttons">
              <button type="submit" className="btn-submit">Submit</button>
            </div>
          </form>
        </div>
      </div>
      
      {/* Contact Cards */}
      <div className="contact-cards">
        <div className="contact-card">
          <div className="phone">
            <p className='icon'><FaMobileScreen /></p>
            <p className='phone-head'>PHONE NUMBER</p>
            <p className='phone-number'>+1 123-456-7890</p> 
          </div>
        </div>
        <div className="contact-card">
          <div className="email">
            <p className='icon'><TfiEmail /></p> 
            <p className='email-head'>EMAIL ADDRESS</p>
            <p className='email-address'>example@email.com</p> 
          </div>
        </div>
        <div className="contact-card">
          <div className="location">
            <p className='icon'><CiLocationOn /></p> 
            <p className='address-head'>ADDRESS INFO</p>
            <p className='address'>62 Dean St, London W1D 4QF, UK</p> 
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
