import React from 'react';
import './Projects.css';
import project1 from './images/Rahul Wagh_4 Bungalows 3D2.jpg'
import project2 from './images/service2.jpg'
import project3 from './images/service3.jpg'

const projectsData = [
  {
    image: project1,
    title: 'Shanti Park',
    description: 'Discover luxury living in our 3 BHK bungalows at Shanti Park, featuring spacious interiors, modern amenities, and eco-friendly designs. Enjoy prime location convenience and 24/7 security in a serene, gated community. ',
  },
  {
    image:project2,
    title: 'Hospital Building',
    description: 'Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem ipsum dolor consectetur Fusce varius.',
  },
  {
    image: project3,
    title: 'Garden House',
    description: 'Lorem ipsum dolor consectetur adipiscing Fusce varius euismod lacus eget feugiat rorem ipsum dolor consectetur Fusce varius.',
  },
];

const Projects = () => {
  return (
    <div className="projects-container">
                        <p className='project-head'>Our Projects</p>
                <div className="divider">
                    <div className="project-line"></div>
                    <div className="project-square"></div>
                    <div className="project-line"></div>
                </div>
                <p className='project-title1'>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>

      <div className="projects-row">
        {projectsData.map((project, index) => (
          <div key={index} className="project-card">
            <img src={project.image} alt={project.title} className="project-image" />
            <h3 className="project-title">{project.title}</h3>
            <p className="project-description">{project.description}</p>
            <a href='/contact'><button  className="read-more-btn" >Read More</button></a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Projects;
