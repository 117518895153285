import React from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaPhoneAlt, FaEnvelope } from 'react-icons/fa';
import logo from './images/Sup Con Logo-01.png'; // Adjust the import if needed
import './Navrouter.css';

const Navrouter = () => {
  return (
    <header>
      <div className="contact-info">
        <span><  FaPhoneAlt className='header-icon' /> +916789453423</span>
        <span><FaEnvelope className='header-icon' /> info@koncrete.com</span>
      </div>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img src={logo} alt="Koncrete Construction Logo" height="40" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about-us">About Us</Nav.Link>
              
              <Nav.Link href="/projects">Projects</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Navrouter;
