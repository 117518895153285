import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from './Home'
import Navrouter from './Navrouter'
import About from './About'
import Footer from './Footer';
import { Contact } from './Contact';
import Services from './Services';
import Projects from './Projects';
function App() {
  return (
    <div >
     <BrowserRouter>
            <Navrouter/>
            <Routes>
                <Route path="/" element={<Home/>} />
               <Route path="/about-us" element={<About />} />
                <Route path="/services" element={<Services />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/contact" element={<Contact/>} /> 
            </Routes>
            <Footer/>
        </BrowserRouter>
    </div>
  );
}

export default App;
